.blurred-background {
    backdrop-filter: blur(
        5px
    ); /* Flou réduit pour rendre la tâche plus visible */
    background-color: rgba(0, 0, 0, 0.5); /* Couleur de fond */
}

.background-blob {
    position: absolute;
    bottom: 40px; /* Positionnement plus bas */
    right: 75px; /* Positionnement plus à droite */
    width: 250px; /* Largeur */
    height: 150px; /* Hauteur */
    background: linear-gradient(
        135deg,
        rgba(75, 0, 130, 0.7) 0%,
        rgba(75, 0, 130, 0.7) 100%
    ); /* Violet foncé */
    border-radius: 50%; /* Rend la forme ovale */
    filter: blur(30px); /* Flou modéré */
    z-index: -1; /* Assurez-vous que l'élément reste derrière */
}

.cursor {
    display: inline-block;
    width: 10px;
    height: 22px;
    background-color: rgb(0, 40, 0);
    margin-left: 12px;
    animation: blink 1s steps(1) infinite;
    vertical-align: bottom;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}
